import React from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import styles from "./MainHeader.module.scss";
import { Link } from "react-router-dom";

export default function MainHeader() {
  return (
    <Container>
      <header className={styles.mainHeader}>
        <div className={styles.myntra}>ॐ नमो भगवते वासुदेवाय नमः</div>
        <div className={styles.headerInner}>
          <div>
            <img src="/images/main-logo.png" width={200} alt="" />
          </div>
          <div>
            <Link to="/login" className={styles.loginBtn}>
              Login
            </Link>
          </div>
        </div>
      </header>
    </Container>
  );
}
