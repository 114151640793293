import React from "react";
import MainHeader from "../../components/MainHeader/MainHeader";
import Banner from "./components/Banner/Banner";
import GrowBusiness from "./components/GrowBusiness/GrowBusiness";
import Partners from "./components/Partners/Partners";
import Expertise from "./components/Expertise/Expertise";
import Faq from "./components/Faq/Faq";
import Footer from "../../components/Footer/Footer";

export default function Main() {
  return (
    <>
      <MainHeader />
      <Banner />
      <GrowBusiness />
      <Partners />
      <Expertise />
      <Faq />
      <Footer />
    </>
  );
}
