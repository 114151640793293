import React from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import styles from "./Expertise.module.scss";

export default function Expertise() {
  return (
    <section className={styles.expertiseStyles}>
      <Container>
        <Grid container spacing={2} alignItems="center">
          <Grid size={{ md: 5 }}>
            <div className="heading">
              <h2>Our partner reflect our expertise</h2>
              <p>
                Join companies who trust our platform and our unique and
                reliable lending solution.
              </p>
            </div>
          </Grid>
          <Grid size={{ md: 7 }}>
            <div className={styles.images}>
              <img src="/images/experts.png" alt="" />
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
