import React from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import styles from "./Faq.module.scss";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";

export default function Faq() {
  return (
    <section className={styles.faqStyles}>
      <Container>
        <Grid container spacing={2} alignItems="center">
          <Grid size={{ md: 5 }}>
            <div className="heading">
              <h2>Got a question? Get your answer</h2>
              <p>
                Quick answers to questions you may have. Can't find what you're
                looking for? Get in touch with us.
              </p>
            </div>
          </Grid>
          <Grid size={{ md: 7 }}>
            <div className={styles.faqList}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  1. What is the DSA program at Ram Fincorp?
                </AccordionSummary>
                <AccordionDetails>
                  The DSA (Direct Selling Agent) program is a partnership
                  opportunity where you can earn attractive commissions by
                  referring potential customers to Ram Fincorp for loans. It's a
                  straightforward way to grow your income by leveraging your
                  network.
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  2. What are the benefits of becoming a DSA with Ram Fincorp?
                </AccordionSummary>
                <AccordionDetails>
                  The DSA (Direct Selling Agent) program is a partnership
                  opportunity where you can earn attractive commissions by
                  referring potential customers to Ram Fincorp for loans. It's a
                  straightforward way to grow your income by leveraging your
                  network.
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  3. How can I become a DSA for Ram Fincorp?
                </AccordionSummary>
                <AccordionDetails>
                  The DSA (Direct Selling Agent) program is a partnership
                  opportunity where you can earn attractive commissions by
                  referring potential customers to Ram Fincorp for loans. It's a
                  straightforward way to grow your income by leveraging your
                  network.isse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  4. Who can become a DSA?
                </AccordionSummary>
                <AccordionDetails>
                  The DSA (Direct Selling Agent) program is a partnership
                  opportunity where you can earn attractive commissions by
                  referring potential customers to Ram Fincorp for loans. It's a
                  straightforward way to grow your income by leveraging your
                  network.isse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  5. What support will I get after signing up?
                </AccordionSummary>
                <AccordionDetails>
                  The DSA (Direct Selling Agent) program is a partnership
                  opportunity where you can earn attractive commissions by
                  referring potential customers to Ram Fincorp for loans. It's a
                  straightforward way to grow your income by leveraging your
                  network.isse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
