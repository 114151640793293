import React from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
import { MdPhoneInTalk } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";

import { FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaXTwitter, FaYoutube } from "react-icons/fa6";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <Container>
        <Grid container spacing={2}>
          <Grid size={{ md: 4 }}>
            <div className={styles.footerInner} style={{ paddingRight: 20 }}>
              <img
                src="/images/logo3.png"
                alt=""
                className={styles.footerLogo}
              />
              <p>
                Ramfincorp.com (Kundanmal Technology Private Limited) is digital
                platform and lending partners of R. K. Bansal Finance Pvt.
                Ltd.(NBFC) that facilities its users to avail financial credit
                from R. K. Bansal Finance Pvt Ltd.
              </p>

              <h3 style={{ fontWeight: "normal", marginBottom: 10 }}>
                Quick Connect
              </h3>
              <div className={styles.contactsList}>
                <div className={styles.icon}>
                  <MdPhoneInTalk color="#ffffff" size={22} />
                </div>
                <div style={{ fontSize: 18, color: "#ffffff" }}>
                  +91 98999-85495
                </div>
              </div>

              <div className={styles.contactsList}>
                <div className={styles.icon}>
                  <MdOutlineEmail color="#ffffff" size={22} />
                </div>
                <div>
                  <p>info@ramfincorp.com</p>
                </div>
              </div>

              <br />

              <ul className={styles.socialLinks}>
                <li>
                  <Link to="/">
                    <FaFacebookSquare size={25} />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <FaInstagram size={25} />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <FaLinkedin size={25} />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <FaXTwitter size={25} />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <FaYoutube size={25} />
                  </Link>
                </li>
              </ul>
            </div>
          </Grid>

          <Grid size={{ md: 2 }}>
            <div className={styles.footerInner}>
              <h3>For Our Users</h3>

              <ul>
                <li>
                  <Link to="/">Terms and Conditions</Link>
                </li>
                <li>
                  <Link to="/">Interest Rate Policy</Link>
                </li>
                <li>
                  <Link to="/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/">Credit Policy</Link>
                </li>
                <li>
                  <Link to="/">Lending Policy</Link>
                </li>
                <li>
                  <Link to="/">Refund and Cancellation</Link>
                </li>
                <li>
                  <Link to="/">Disclaimer and Disclosure</Link>
                </li>
                <li>
                  <Link to="/">Fair Practice Code</Link>
                </li>
              </ul>
            </div>
          </Grid>

          <Grid size={{ md: 3 }}>
            <div className={styles.footerInner}>
              <h3>Loan Services</h3>

              <ul>
                <li>
                  <Link to="/">Instant Personal Loans</Link>
                </li>
                <li>
                  <Link to="/">Loan On Aadhaar Card</Link>
                </li>
                <li>
                  <Link to="/">Personal Loan for Salaried</Link>
                </li>
                <li>
                  <Link to="/">Credit Score Personal Loans</Link>
                </li>
                <li>
                  <Link to="/">Our Presence</Link>
                </li>
              </ul>
            </div>
          </Grid>

          <Grid size={{ md: 3 }}>
            <div className={styles.footerInner}>
              <h3>Company</h3>

              <ul>
                <li>
                  <Link to="/">About Us</Link>
                </li>
                <li>
                  <Link to="/">Contact Us</Link>
                </li>
                <li>
                  <Link to="/">Our Partner</Link>
                </li>
                <li>
                  <Link to="/">Refund and Cancellation</Link>
                </li>
                <li>
                  <Link to="/">Grievance Redressal</Link>
                </li>
                <li>
                  <Link to="/">Grievance Redressal Policy</Link>
                </li>
                <li>
                  <Link to="/">R.K. Bansal Finance</Link>
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Container>

      <div className={styles.copyright}>
        Copyright © 2024 By Kundanmal Technology Private Limited
      </div>
    </footer>
  );
}
