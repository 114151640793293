import React from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import styles from "./Banner.module.scss";
import { FaCheck } from "react-icons/fa6";
import RegisterForm from "./RegisterForm";

export default function Banner() {
  return (
    <section className={styles.banner}>
      <Container>
        <Grid container spacing={2}>
          <Grid size={{ md: 6 }}>
            <div className={styles.bannerContent}>
              <h1>
                Earn <br /> <span>₹4,00,000 p.m.</span> <br /> by being our
                <br /> <span>DSA</span> partner
              </h1>

              <div className={styles.list}>
                <div className={styles.icons}>
                  <FaCheck color="#2264dc" size={15} />
                </div>
                <div className={styles.listContent}>
                  3% Commission on loan disbursal
                </div>
              </div>

              <div className={styles.list}>
                <div className={styles.icons}>
                  <FaCheck color="#2264dc" size={15} />
                </div>
                <div className={styles.listContent}>
                  All digital process instant disbursal loan upto 8 lakh
                </div>
              </div>
            </div>
          </Grid>
          <Grid size={{ md: 6 }}>
            <div
              className={styles.bannerForm}
              style={{ backgroundImage: `url("/images/banner-bg.jpg")` }}
            >
              <div className={styles.innerForm}>
                <h2>Get Started</h2>
                <p>Welcome to Ram Fincorp - Your Partner in Financial Growth</p>

                <RegisterForm />
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
