import React from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import styles from "./GrowBusiness.module.scss";
import { FaCheck } from "react-icons/fa6";

export default function GrowBusiness() {
  return (
    <section className={styles.containerStyle}>
      <Container>
        <Grid container spacing={2}>
          <Grid size={12}>
            <div className="heading">
              <h2>
                Grow your business with a <br /> wider reach
              </h2>
              <p>
                Choose us for speed, accuracy, and complete satisfaction for
                your clients
              </p>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid size={{ md: 4 }}>
            <div className={styles.content}>
              <div>
                <img src="/images/icons/icon1.png" alt="" />
              </div>
              <div>
                <h3>Quick Response to your clients</h3>
                <p>
                  File your clients and guide them through a fast and secure
                  approval process. Loan Disbursal has never been easier!
                </p>
              </div>
            </div>
          </Grid>
          <Grid size={{ md: 4 }}>
            <div className={styles.content}>
              <div>
                <img src="/images/icons/icon2.png" alt="" />
              </div>
              <div>
                <h3>Using cutting-edge technology for approvals</h3>
                <p>
                  Our innovative technology brings the most to the table while
                  maintaining the tightest of data security.
                </p>
              </div>
            </div>
          </Grid>
          <Grid size={{ md: 4 }}>
            <div className={styles.content}>
              <div>
                <img src="/images/icons/icon3.png" alt="" />
              </div>
              <div>
                <h3>Dependable cultivation of your clients</h3>
                <p>
                  Bring the best of the best to your best clients with a quick
                  and reliable loan application.
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
