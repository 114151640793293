import React from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import styles from "./Partners.module.scss";

export default function Partners() {
  return (
    <section className={styles.partnerStyles}>
      <Container>
        <Grid container spacing={2}>
          <Grid size={{ md: 8 }} offset={{ md: 2 }}>
            <div className="heading">
              <h2>Why should you become a Ram fincorp DSA Partner?</h2>
              <p>
                Experience our tech-driven solutions, personalized <br />
                attention, and client-centered philosophy.
              </p>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid size={{ md: 4 }}>
            <div className={styles.partners}>
              <img src="/images/partner1.jpg" alt="" />
              <div className={styles.overlay}>
                Opportunity to earn fascinating commission on disbursements
              </div>
            </div>
          </Grid>

          <Grid size={{ md: 4 }}>
            <div className={styles.partners}>
              <img src="/images/partner2.jpg" alt="" />
              <div className={styles.overlay}>
                Establish a successful reputation amongst your clients
              </div>
            </div>
          </Grid>

          <Grid size={{ md: 4 }}>
            <div className={styles.partners}>
              <img src="/images/partner3.jpg" alt="" />
              <div className={styles.overlay}>
                Excellent service <br /> and support for you
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
